import { ExclamationCircleOutlined } from '@ant-design/icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'
import { Button, Dropdown, Layout, Menu, Modal, Spin } from 'antd'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import Helmet from 'react-helmet'
import { isAdmin, isLoggedIn, isManager, isOwner } from '../api/auth'
import '../styles/global-version.scss'

const { confirm } = Modal
const { Header, Content, Footer } = Layout
const year = new Date().getFullYear()

export default function AdminLayout(props) {
  const { pathname } = useLocation()
  const [currentMenu, setCurrentMenu] = useState(['/'])
  const current = pathname.split('/admin')[1].split('/')[1]
  const [firstName, setFirstName] = useState(undefined)

  useEffect(() => {
    setFirstName(localStorage.getItem('firstName'))
  }, [])

  const menus = useMemo(
    () =>
      isAdmin() || isOwner()
        ? [
            {
              title: 'Илгээмжүүд',
              dataIndex: 'orders',
              href: '/admin/orders',
            },
            {
              title: 'Багц илгээмж',
              dataIndex: 'bulkorders',
              href: '/admin/bulkorders',
            },
            {
              title: 'Богц',
              dataIndex: 'shipments',
              href: '/admin/shipments',
            },
            {
              title: 'Хэрэглэгч',
              dataIndex: 'users',
              href: '/admin/users',
            },
            {
              title: 'Тохиргоо',
              dataIndex: 'configuration',
              href: '/admin/configuration',
            },
            {
              title: 'Тайлан',
              dataIndex: 'reports',
              href: '/admin/reports',
            },
          ]
        : isManager()
        ? [
            {
              title: 'Богц',
              dataIndex: 'shipments',
              href: '/admin/shipments',
            },
            {
              title: 'Тайлан',
              dataIndex: 'reports',
              href: '/admin/reports',
            },
          ]
        : [],
    [],
  )
  useEffect(() => {
    if (!current) setCurrentMenu(['/'])
    else setCurrentMenu([current])
  }, [current, menus])

  const headerDropdownStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    paddingBottom: 2,
    flexGrow: 1,
    justifyContent: 'flex-end',
  }
  if (!isLoggedIn() || !(isAdmin() || isManager() || isOwner())) {
    if (typeof window !== 'undefined') window.location.href = '/admin/login'
    //navigate(`/admin/login`);
  }
  return isLoggedIn() && (isAdmin() || isManager() || isOwner()) ? (
    <Layout style={{ background: 'white' }} className="main-layout">
      <Helmet title="Админ - SB Express" defer={false} />
      <Header className="sb-shadow bg-base">
        <div className="sb-container d-flex">
          <Menu mode="horizontal" id="sb-header-menu" selectedKeys={currentMenu}>
            {menus.map((menu) => {
              return (
                <Menu.Item key={menu.dataIndex} onClick={() => navigate(menu.href)}>
                  {menu.title}
                </Menu.Item>
              )
            })}
          </Menu>
          <div style={headerDropdownStyle}>
            <Dropdown
              placement="bottomRight"
              className="layout-header-dropdown"
              overlayClassName="layout-header-dropdown-overlay"
              menu={{
                items: [
                  {
                    label: (
                      <Button
                        type="link"
                        onClick={() => {
                          confirm({
                            title: 'Та гарахдаа итгэлтэй байна уу?',
                            maskClosable: true,
                            icon: <ExclamationCircleOutlined />,
                            cancelText: 'Үгүй',
                            okText: 'Тийм',
                            okType: 'danger',
                            className: 'confirm-hs-modal',
                            centered: true,
                            confirmLoading: true,
                            onOk() {
                              sessionStorage.removeItem('paginatedRequestData')
                              localStorage.clear()
                              navigate('/admin/login')
                            },
                          })
                        }}
                      >
                        Гарах
                      </Button>
                    ),
                  },
                ],
              }}
            >
              <div>
                <div className="me-4">{firstName ?? ''}</div>
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content>
        <div style={{ maxWidth: '1366px', margin: 'auto', padding: '20px 30px' }}>{props.children}</div>
      </Content>
      <Footer className="sb-footer text-center bg-white">S.B. Express, INC {year}</Footer>
    </Layout>
  ) : (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin />
    </div>
  )
}
